import React from 'react'
import { graphql } from 'gatsby'
import NotFoundPage from '../pages/404'

export const RattishSettingsTemplate = ({ id }) => {
  return <NotFoundPage />
}

export default ({ data }) => {
  const { markdownRemark: rattishSettings } = data

  return <RattishSettingsTemplate />
}

export const RattishSettingsQuery = graphql`
  query RattishSettings($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        categoryItems {
          name
          slug
        }
      }
    }
  }
`
